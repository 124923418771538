angular
    .module('app')
    .component('pyramidsFilter', {
        templateUrl: 'app/pyramid/pyramids-filter.html',
        controllerAs: 'vm',
        // Require the parent pyramids component
        require: {
            pyramids: '^pyramids'
        },
        controller: function ($scope, $state, pyramidsFactory, countryService) {
            const self = this;

            // Run when component is ready
            self.$onInit = function () {
                // Initialize stick em up for the nav
                $('.pyramids-filter-section').stickEmUp({
                    stickOffset: -250
                })

                $scope.$on('search-brand', function (event, args) {
                    self.runFilter(args)
                });
                // Set the countries to used in select
                self.countries = countryService;
                // fetch all ethos
                pyramidsFactory.fetchEthos().then(function (response) {
                    self.ethos = response.data;
                }, function (error) {
                    console.log(error);
                });

                // fetch all categories
                pyramidsFactory.fetchCategories().then(function (response) {
                    self.categories = response.data;
                }, function (error) {
                    console.log(error);
                });

                // fetch all authors
                pyramidsFactory.fetchAuthors().then(function (response) {
                    self.authors = response.data;
                }, function (error) {
                    console.log(error);
                });

                // fetch all categories
                pyramidsFactory.fetchThemes().then(function (response) {
                    self.themes = response.data;
                }, function (error) {
                    console.log(error);
                });
            };

            self.runFilter = function (filter) {
                pyramidsFactory.search(filter)
                    .then(function (response) {
                        // Update view
                        self.pyramids.pyramids = response.data.pyramids;
                        // Set search count
                        self.searchCount = response.data.pyramids.length;
                        // Set the ethos values
                        self.cp = response.data.challengeAndPioneering;
                        self.cc = response.data.commitmentAndCare;
                        self.mr = response.data.masteryAndRefinement;
                        self.pa = response.data.possibilityAndAbundance;

                        //Set the authors
                        self.searchAuthors = response.data.authors;

                    }, function (error) {
                        console.log(error);

                    });


                // $scope.$emit('filterEthos', {
                //     id: self.selectedEthos
                // });
            }

        }

    });
