angular
    .module('app')
    .directive('stopPropagation', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.bind('click', function (e) {
                    e.stopPropagation();
                });
            }
        };
    })
    .directive('scrollToNode', function () {
        return {
            restrict: 'A',
            scope: {
                scrollTo: '@',
                scrollOffset: '@'
            },
            link: function (scope, element) {
                element.on('click', function () {
                    $('html,body').animate({
                        scrollTop: Math.floor($(scope.scrollTo).offset().top) - Math.floor(scope.scrollOffset)
                    }, 'slow');
                });
            }
        }
    })
    .directive('filterEthos', function () {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.bind('click', function (e) {
                    // Remove active class from other filters
                    $('.ethos-filter').removeClass('active');
                    // Add the active class
                    element.addClass('active');
                    // Check if Show all was selected
                    if (element.attr('data-id') === 'ethos-0') {
                        // Show all ethos
                        $('[id^=ethos-]').show();
                    } else {
                        // Hide all ethos
                        $('[id^=ethos-]').hide();
                        // Show selected ethos
                        $('#' + element.attr('data-id')).show();
                    }
                });
            }
        };
    });
