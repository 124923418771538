angular
    .module('app')
    .component('login', {
        templateUrl: 'app/auth/login.html',
        controllerAs: 'vm',

        controller: function (__env, authFactory, userFactory, sweetAlert, $timeout, $localStorage, $state) {
            // Set this to self to avoid conflicts
            const self = this;

            self.$onInit = function () {
                // Set the initial login button
                self.button = 'Login';
            };

            // Handle the login
            self.loginHandler = function () {
                self.button = 'Logging in..';
                const input = {
                    grant_type: 'password',
                    client_id: __env.apiID,
                    client_secret: __env.apiSecret,
                    username: self.email,
                    password: self.password
                };

                // Call the authFactory
                authFactory.login(input)
                    .then(function (response) {
                        // Set the local storage access token
                        $localStorage.accessToken = response.data.access_token;
                        // Fetch the user
                        userFactory.fetch()
                            .then(function (response) {
                                $localStorage.user = response.data;
                                // Go to the app
                                $state.go('app');
                            }, function (error) {
                                console.log(error);
                            });
                    }, function (error) {
                        // Show sweet alert with callback
                        sweetAlert.swal({
                            title: 'Oops!',
                            text: error.data.message,
                            type: 'error'
                        }).then(function () {
                            $timeout(function () {
                                self.button = 'Login'; // Change login button text back
                            });
                        });
                    });
            };
        }
    });
