angular
    .module('app')
    .factory('commentsFactory', function (__env, $http, $localStorage) {
        return {
            /**
             * Add a new comment
             */
            add: function (input) {
                if (__env.enableDebug) {
                    console.log('commentsFactory.add ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'comment',
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            /**
             * Reply to comment
             */
            reply: function (input) {
                if (__env.enableDebug) {
                    console.log('commentsFactory.reply ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'reply',
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            /**
             * Edit comment
             */
            edit: function (input, commentId) {
                if (__env.enableDebug) {
                    console.log('commentsFactory.reply ran');
                }
                return $http({
                        method: 'PUT',
                        url: __env.apiUrl + 'comment/' + commentId,
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            /**
             * Delete comment
             */
            delete: function (id) {
                if (__env.enableDebug) {
                    console.log('commentsFactory.delete ran');
                }
                return $http({
                        method: 'DELETE',
                        url: __env.apiUrl + 'comment/' + id,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            }
        };
    });
