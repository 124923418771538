angular
    .module('app')
    .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    $locationProvider.html5Mode(true).hashPrefix('!');
    $urlRouterProvider.otherwise('/login');

    $stateProvider
        .state('login', {
            url: '/login',
            component: 'login',
            restricted: false
        })
        .state('logout', {
            url: '/logout',
            template: ' ', // Leaving this blank to avoid error
            controllerAs: 'vm',
            controller: function (authFactory) {
                authFactory.logout();
            }
        })
        .state('reset', {
            url: '/reset',
            component: 'reset',
            restricted: false
        })
        .state('password', {
            url: '/password?token',
            component: 'password',
            restricted: false
        })
        .state('app', {
            url: '/',
            component: 'app',
            // Resolve below before rendering view
            resolve: {
                // Get the all unapproved pyramids 
                unapproved: function (pyramidsFactory, $rootScope, $localStorage) {
                    return pyramidsFactory.fetchUnapproved().then(function (response) {
                        // Set the localStorage unapproved count
                        $localStorage.unapproved = response.data;
                    }, function (error) {
                        console.log(error);
                    });
                }
            },
            restricted: true,
            autoActivateChild: 'app.all-pyramids'
        })
        .state('app.build-pyramid', {
            url: 'build-pyramid',
            component: 'pyramidBuild',
            // Resolve below before rendering view
            resolve: {
                ethos: function (pyramidsFactory) {
                    return pyramidsFactory.fetchEthos().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
                categoriesObj: function (pyramidsFactory) {
                    return pyramidsFactory.fetchCategories().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
                authorsObj: function (pyramidsFactory) {
                    return pyramidsFactory.fetchAuthors().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
                themesObj: function (pyramidsFactory) {
                    return pyramidsFactory.fetchThemes().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                }
            },
            params: {
                pyramid: null
            },
            restricted: true
        })
        .state('app.all-pyramids', {
            url: 'all-pyramids',
            component: 'pyramids',
            params: {
                brand: null
            },
            // Resolve below before rendering view
            resolve: {
                // Get the all pyramids
                pyramids: function (pyramidsFactory) {
                    return pyramidsFactory.fetchAll().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
                users: function (userFactory) {
                    return userFactory.fetchAll().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
            },
            restricted: true
        })
        .state('app.my-pyramids', {
            url: 'my-pyramids',
            component: 'pyramids',
            resolve: {
                // Get the user
                pyramids: function (userFactory) {
                    return userFactory.fetch().then(function (response) {
                        console.log(response);
                        return response.data.pyramid;
                    }, function (error) {
                        console.log(error);
                    });
                },
                users: function (userFactory) {
                    return userFactory.fetchAll().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
            },
            restricted: true
        })
        .state('app.ethos', {
            url: 'ethos',
            component: 'ethos',
            // Resolve below before rendering view
            resolve: {
                // Get the all ethos
                ethos: function (ethosFactory) {
                    return ethosFactory.fetchAll().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                }
            },
            restricted: true
        })
        .state('app.edit-ethos', {
            url: 'ethos',
            component: 'ethosEdit',
            params: {
                ethos: null
            },
            restricted: true
        })
        .state('app.admin', {
            url: 'admin',
            component: 'pyramids',
            // Resolve below before rendering view
            resolve: {
                // Get the all unapproved pyramids
                pyramids: function (pyramidsFactory) {
                    return pyramidsFactory.fetchUnapproved().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
                users: function (userFactory) {
                    return userFactory.fetchAll().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
            },
            restricted: true
        })
        .state('app.super-admin', {
            url: 'super-admin',
            component: 'superAdmin',
            // Resolve below before rendering view
            resolve: {
                // Get the all categories
                categories: function (pyramidsFactory) {
                    return pyramidsFactory.fetchCategories().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
                // Get the all users
                users: function (userFactory) {
                    return userFactory.fetchAll().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
                // Get all the themes
                themes: function (pyramidsFactory) {
                    return pyramidsFactory.fetchThemes().then(function (response) {
                        return response.data;
                    }, function (error) {
                        console.log(error);
                    });
                },
            },
            restricted: true
        });

}
