angular
    .module('app')
    .factory('ethosFactory', function (__env, $http, $localStorage) {
        return {
            fetchAll: function () {
                if (__env.enableDebug) {
                    console.log('ethosFactory.fetchAll ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'ethos',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            }
        };
    });
