angular
    .module('app')
    .component('pyramidBuild', {
        templateUrl: 'app/pyramid/pyramid-build.html',
        controllerAs: 'vm',
        bindings: {
            ethos: '<',
            categoriesObj: '<',
            authorsObj: '<',
            themesObj: '<'
        },

        controller: function ($state, $stateParams, $localStorage, $window, pyramidsFactory, countryService, $timeout) {
            const self = this

            // Run when component is ready
            self.$onInit = function () {
                // Track if we are editing a pyramid
                self.editingPyramid = false;

                // Reload persistant build data
                if ($localStorage.pyramidBuild) {
                    self.build = $localStorage.pyramidBuild;
                } else { // We are starting a new pyramid
                    self.build = {};
                    self.build.authors = []; // Set the inital author as current user
                    // self.build.authors[0] = $localStorage.user.name.toLowerCase();
                }

                // Hide the pyramid notice
                self.hidePyramidNotice = ($localStorage.hidePyramidNotice) ? true : false;

                // Set the button value
                self.button = 'Add Pyramid';

                // Set the countries to used in select
                self.countries = countryService;

                //Set the pyramid years
                self.pyramidYears = [];
                var currentYear = new Date().getFullYear();
                for (var i = 1; i < (currentYear - 1999); i++) {
                    self.pyramidYears.push((1999 + i));
                }
                self.pyramidYears.push(currentYear);

                // Set the initial folder and file arrays
                self.folders = [];
                self.files = [];

                // Set the categories
                self.categories = [];
                angular.forEach(self.categoriesObj, function (value, key) {
                    self.categories.push(value.category);
                });

                // Set the authors
                self.authors = [];
                angular.forEach(self.authorsObj, function (value, key) {
                    var author = value.author.toLowerCase()
                    if (self.authors.indexOf(author) < 0) {
                        self.authors.push(author)
                    }
                });

                // Add the current user if they are not already an author
                const currentUser = $localStorage.user.name.toLowerCase();
                if (self.authors.indexOf(currentUser) < 0) {
                    self.authors.push(currentUser);
                }

                // Set the themes
                self.themes = [];
                angular.forEach(self.themesObj, function (value, key) {
                    self.themes.push(value.name);
                });

                // Check if we are editing
                if ($stateParams.pyramid !== null) {
                    // Track if we are editing
                    self.editingPyramid = true;

                    // Set the button value
                    self.button = 'Save Pyramid';

                    // Track if we are editing or adding
                    self.build = $stateParams.pyramid;

                    // Set the pyramid themes to the theme model
                    self.build.theme = self.build.themes;

                    self.build.date = new Date(self.build.date);

                    // Set the editing pyramid month and year
                    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                    $timeout(function () {
                        self.build.month = months[parseInt(self.build.date.getMonth())];
                        self.build.year = self.build.date.getFullYear() + ''; // convert to string

                        // Convert pyramid country to array
                        if (self.build.country) {
                            self.build.country = self.build.country.split(';').map(function (country) {
                                return country.trim();
                            });
                        }
                        // Convert pyramid category to array
                        if (self.build.category) {
                            self.build.category = self.build.category.split(';').map(function (category) {
                                return category.trim();
                            });
                        }
                        // Convert pyramid themes to array
                        if (self.build.theme) {
                            self.build.theme = self.build.theme.split(';').map(function (theme) {
                                return theme.trim();
                            });
                        }
                        // Convert pyramid authors to array
                        if (self.build.authors) {
                            self.build.authors = self.build.authors.split(';').map(function (authors) {
                                return authors.trim();
                            });
                        }
                    });

                    if (self.build.folder_id !== null || self.build.file_id !== null) {
                        // Get the Google Drive files and folders
                        self.shareGoogleDrive();
                    }
                }
            };

            // Save persistant build data
            self.saveBuild = function () {
                if (!self.editingPyramid) {
                    $localStorage.pyramidBuild = self.build;
                }
            }

            // Handle pyramid notice
            self.handlePyramidNotice = function () {
                // Hide the notice
                $('.alert.alert-info.pyramid-notice').fadeOut();
                // Set the localStorage value
                $localStorage.hidePyramidNotice = true;
            }

            // Cancel Pyramid
            self.cancelPyramid = function () {
                // Clear persistant build data
                $localStorage.pyramidBuild = null;
                // Go back from whence you came
                $window.history.back();
            }

            // Build our pyramid
            self.buildPyramid = function () {
                // Build new date form year and month
                if (self.build.month !== undefined && self.build.year !== undefined) {
                    self.build.date = (new Date(self.build.month + ' ' + self.build.year + ' 23:59:59')).toISOString().substring(0, 10);
                } else {
                    swal('Oops!', 'Please select a valid date', 'error');

                    return false;
                }

                // Set the current user as the first author
                self.build.authors.unshift($localStorage.user.name.toLowerCase());

                // Change the button
                self.button = 'Adding Pyramid...'
                // Run the create pyramid function
                pyramidsFactory.create(self.build)
                    .then(function (response) {
                        swal({
                            title: 'Thank you for building this collection.',
                            text: 'You will hear from us soon, your pyramid is being approved.',
                            customClass: 'build-pyramid-success',
                            imageUrl: '../assets/img/smiley.png',
                            imageClass: 'build-pyramid-smiley',
                            type: 'success'
                        });

                        // Clear saved build
                        $localStorage.pyramidBuild = null;

                        // Go back to start, do not pass go, do not collect R200
                        $state.go('app.my-pyramids');

                    }, function (error) {
                        var msg = '';
                        for (prop in error.data.message) {
                            msg += error.data.message[prop][0] + '<br>'
                        }
                        swal('Oops!', msg, 'error');

                        $timeout(function () {
                            // Change the button
                            self.button = 'Add Pyramid'
                        })
                    });
            };

            // Update our pyramid
            self.updatePyramid = function () {
                // Build new date form year and month
                if (self.build.month !== undefined && self.build.year !== undefined) {
                    self.build.date = (new Date(self.build.month + ' ' + self.build.year + ' 23:59:59')).toISOString().substring(0, 10);
                } else {
                    swal('Oops!', 'Please select a valid date', 'error');

                    return false;
                }
                // Set the theme to themes model when editing
                self.build.themes = self.build.theme;
                self.button = 'Saving Pyramid...'

                pyramidsFactory.update(self.build)
                    .then(function (response) {
                        swal('Pyramid information saved', response.message, 'success');
                        $timeout(function () {
                            self.button = 'Save Pyramid';

                            // Redirect depending on user
                            if ($localStorage.user.role[0].id === 3) {
                                $state.go('app.my-pyramids', {}, {
                                    reload: true
                                });
                            } else {
                                $state.go('app.admin', {}, {
                                    reload: true
                                });
                            }
                        });
                    }, function (error) {
                        var msg = '';
                        for (prop in error.data.message) {
                            msg += error.data.message[prop][0] + '<br>'
                        }
                        swal('Oops!', msg, 'error');

                        $timeout(function () {
                            self.button = 'Save Pyramid';
                        })
                    });
            };

            // Share Google Drive files and folders
            self.shareGoogleDrive = function (adding) {
                // The Browser API key obtained from the Google API Console.
                //const developerKey = 'AIzaSyDvmE3ZZbsbUbEOXgnwr5UZ5L5zZ2hqP2E';
                const developerKey = 'AIzaSyA84wbERGRYr1nJHDckeKGOMzfvPryei0o'; // lliam

                // The Client ID obtained from the Google API Console. Replace with your own Client ID.
                //const clientId = "1018936847217-4eble25sq5bfpqjqhtf8ilrr6e2fo1au.apps.googleusercontent.com"
                const clientId = "744741621323-57is6rqjsfn967v03khs6cif578e6meu.apps.googleusercontent.com"; //liam

                // Scope to use to access user's photos.
                const scope = ['https://www.googleapis.com/auth/drive'];

                gapi.auth.authorize({
                        'client_id': clientId,
                        'scope': scope,
                        'immediate': true
                    },
                    handleAuthResult);

                function handleAuthResult(authResult) {
                    if (authResult && !authResult.error) {
                        self.oauthToken = authResult.access_token;
                        if (self.editingPyramid && (self.build.folder_id !== null || self.build.file_id !== null)) {
                            gapi.client.load('drive', 'v2', function () {
                                if (!adding) {
                                    if (self.build.folder_id) {
                                        self.build.folder_id = self.build.folder_id.toString().split(';')
                                        // Build the folder object
                                        self.buildFolderObject(self.build.folder_id);
                                    }
                                    if (self.build.file_id) {
                                        self.build.file_id = self.build.file_id.toString().split(';')
                                        // Build the file object
                                        self.buildFileObject(self.build.file_id);
                                    }
                                } else {
                                    gapi.load('picker', {
                                        'callback': function () {
                                            createPicker();
                                        }
                                    });
                                }
                            });
                        } else {
                            gapi.load('picker', {
                                'callback': function () {
                                    createPicker();
                                }
                            });
                        }
                    } else {
                        gapi.auth.authorize({
                                'client_id': clientId,
                                'scope': scope,
                                'immediate': false
                            },
                            handleAuthResult);
                    }
                }

                // Create and render a Picker object for picking user Photos.
                function createPicker() {
                    var docsView = new google.picker.DocsView().
                    setIncludeFolders(true).
                    // setMimeTypes('application/vnd.google-apps.folder').
                    // setMimeTypes('application/vnd.google-apps.file').
                    setSelectFolderEnabled(true);
                    var picker = new google.picker.PickerBuilder().
                    // addView(google.picker.ViewId.PHOTOS).
                    // addView(google.picker.ViewId.DOCS).
                    addView(docsView).
                    enableFeature(google.picker.Feature.MULTISELECT_ENABLED).
                    setOAuthToken(self.oauthToken).
                    setDeveloperKey(developerKey).
                    setCallback(pickerCallback).
                    build();
                    picker.setVisible(true);
                }

                // Main callback to handle docs selection
                function pickerCallback(data) {
                    gapi.client.load('drive', 'v2', function () {
                        var url = 'nothing';

                        if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
                            var doc = data[google.picker.Response.DOCUMENTS][0];
                            url = doc[google.picker.Document.URL];

                            /* Loop through data returned and build folder_id & file_id array
                            This will be passed to the API */
                            angular.forEach(data.docs, function (value, key) {
                                if (value.mimeType === 'application/vnd.google-apps.folder') {
                                    if (!self.build.folder_id) {
                                        self.build.folder_id = [];
                                    }
                                    self.build.folder_id.push(value.id);
                                } else {
                                    if (!self.build.file_id) {
                                        self.build.file_id = [];
                                    }
                                    self.build.file_id.push(value.id);
                                }
                            });

                            // Build the folder object
                            self.buildFolderObject(self.build.folder_id);
                            // Build the file object
                            self.buildFileObject(self.build.file_id);

                        }
                    });
                }
            }

            /**
             * GOOGLE DRIVE FUNCTIONS
             */
            var backOff = 1200; // Exponential backOff
            var count = 1; // Track file count as Google only allows 10 API requests per 1 second

            // Build the folder object function
            self.buildFolderObject = function (folderID) {
                // Build the folders
                angular.forEach(folderID, function (value, key) {
                    var request = gapi.client.drive.files.get({
                        'fileId': value
                    });

                    request.execute(function (response) {
                        var folderName = response.title
                        // Set the folder object
                        var folder = {
                            folder_id: value,
                            folder_name: folderName,
                            files: []
                        }

                        // Build folder object
                        retrieveAllFilesInFolder(value, function (result) {
                            angular.forEach(result, function (v, k) {
                                getFileProps(v.id, function (result) {
                                    // console.log(result);
                                    if (result.code === 403) { // Re-run if we get an error
                                        if (backOff > 17000) { // Track exponential backOff to retry
                                            backOff = 1200;
                                        } else {
                                            backOff = backOff * 2;
                                        }

                                        getFileProps(v.id, function (result) {
                                            // Set the file object
                                            var file = {
                                                file_id: result.id,
                                                thumbnailLink: result.thumbnailLink,
                                                title: result.title,
                                                embedLink: result.embedLink
                                            }
                                            // Check if folder has sub folders
                                            if (result.thumbnailLink !== undefined) {
                                                // Push file to files array
                                                folder.files.push(file);
                                            }
                                        });
                                    } else {
                                        // Set the file object
                                        var file = {
                                            file_id: result.id,
                                            thumbnailLink: result.thumbnailLink,
                                            title: result.title,
                                            embedLink: result.embedLink
                                        }
                                        // Check if folder has sub folders
                                        if (result.thumbnailLink !== undefined) {
                                            // Push file to files array
                                            folder.files.push(file);
                                        }
                                    }
                                });
                            });
                            // Check if folder has been duplicated
                            var duplicate = self.folders.some(function (el) {
                                return el.folder_id === value;
                            });
                            // Only push to folder array if not duplicate
                            $timeout(function () {
                                (!duplicate) ? self.folders.push(folder): console.log('Folder duplicate: folder not added');
                            });

                            $timeout(function () {
                                console.log(self.folders);
                            }, 20000);
                        });
                    });
                });
            }

            // Build the file object function
            self.buildFileObject = function (fileID) {
                // Build the files object
                angular.forEach(fileID, function (value, key) {
                    getFileProps(value, function (result) {
                        // Set the file object
                        var file = {
                            file_id: result.id,
                            thumbnailLink: result.thumbnailLink,
                            title: result.title,
                            embedLink: result.embedLink
                        }
                        // Check if file has been duplciated
                        var duplicate = self.files.some(function (el) {
                            return el.file_id === value;
                        });
                        // Only push to file array if not duplicate
                        $timeout(function () {
                            (!duplicate) ? self.files.push(file): console.log('File duplicate: file not added');
                        })
                    });
                });

                $timeout(function () {
                    console.log(self.files);
                }, 5000);
            }

            // Retrieve list of files in folder
            function retrieveAllFilesInFolder(folderId, callback) {
                var retrievePageOfChildren = function (request, result) {
                    request.execute(function (resp) {
                        result = result.concat(resp.items);
                        var nextPageToken = resp.nextPageToken;
                        if (nextPageToken) {
                            request = gapi.client.drive.children.list({
                                'folderId': folderId,
                                'pageToken': nextPageToken
                            });
                            retrievePageOfChildren(request, result);
                        } else {
                            callback(result);
                        }
                    });
                }

                var initialRequest = gapi.client.drive.children.list({
                    'folderId': folderId
                });
                retrievePageOfChildren(initialRequest, []);
            }

            // Get the file props
            function getFileProps(fileId, callback) {
                $timeout(function () {
                    self.sharingGoogle = true;
                }); // Show the loader

                const request = gapi.client.drive.files.get({
                    'fileId': fileId
                });
                count++
                if (count >= 10) {
                    $timeout(function () {
                        self.sharingGoogle = false;
                        request.execute(function (resp) {
                            callback(resp);
                        });
                    }, backOff);
                } else {
                    $timeout(function () {
                        self.sharingGoogle = false;
                        request.execute(function (resp) {
                            callback(resp);
                        });
                    });
                }
            }

        }

    });