angular
    .module('app')
    .component('ethosEdit', {
        templateUrl: 'app/ethos/ethos-edit.html',
        controllerAs: 'vm',

        controller: function ($stateParams, $state, pyramidsFactory) {
            const self = this

            // Run when component is ready
            self.$onInit = function () {
                self.build = $stateParams.ethos;
            };

            self.removeItem = function (str, i) {
                var arr = str.split('|');
                var str = '';

                // Remove our item
                arr.splice(i, 1);

                // rebuild string
                angular.forEach(arr, function (value, key) {
                    str += value + '|';
                });
                // Remove trailing pipe
                str = str.substring(0, str.length - 1)

                // Return new string
                return str;
            }

            // Cancel Ethos
            self.cancelEthos = function () {
                $state.go('app.ethos');
            }

            // Save the ethos
            self.saveEthos = function () {
                pyramidsFactory.saveEthos(self.build)
                    .then(function (response) {
                        swal('Ethos information saved', response.message, 'success');
                        // Reload the ethos
                        $state.go('app.ethos', {}, {
                            reload: true
                        });
                    }, function (error) {
                        console.log(error);
                    });

            }
        }

    });
