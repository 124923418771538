angular
    .module('app')
    .component('ethos', {
        templateUrl: 'app/ethos/ethos.html',
        controllerAs: 'vm',
        bindings: {
            ethos: '<'
        },

        controller: function ($state, $localStorage) {
            // Set this to self to avoid conflicts
            const self = this;

            // Run when component is ready
            self.$onInit = function () {
                // Set the ethos colors
                self.ethosColors = ['pink', 'green', 'orange', 'blue'];

                self.user = $localStorage.user;
            }

            self.editEthos = function (ethos) {
                $state.go('app.edit-ethos', {
                    ethos: ethos
                });
            }
        }


    });
