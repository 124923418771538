angular
    .module('app')
    .component('believersHeader', {
        templateUrl: 'app/header.html',
        controllerAs: 'vm',

        controller: function ($state, $localStorage, $rootScope, $scope) {
            // Set this to self to avoid conflicts
            const self = this;

            // Set the state to track active menu items
            self.state = $state;

            // Run when component is ready
            self.$onInit = function () {
                // Initialize stick em up for the nav
                $('.navbar').stickEmUp({
                    stickOffset: -36,
                    callback: function (info) {
                        if (info.stickEmUp) {
                            $('.main').addClass('stickEmUp');
                        } else {
                            $('.main').removeClass('stickEmUp');
                        }

                    }
                });

                // Set the unapproved pyramid count
                self.unapprovedCount = $localStorage.unapproved.length;

                // Set the user here
                self.user = $localStorage.user;
            };

            $scope.$on('update-unapproved-count', function (event, args) {
                self.unapprovedCount = args.count;
            });

            self.runFilter = function () {
                $state.go('app.all-pyramids', {
                    brand: self.filter.brand
                })
            }

            // Navigate to add pyramid
            self.handleBuildPyramid = function () {
                // Go to build pyramid state and reset the editing pyramid details
                $state.go('app.build-pyramid', {
                    pyramid: null
                });
            }
        }
    });
