angular
    .module('app')
    .component('pyramids', {
        templateUrl: 'app/pyramid/pyramids.html',
        controllerAs: 'vm',
        bindings: {
            pyramids: '<',
            users: '<'
        },

        controller: function ($state, $stateParams, pyramidsFactory, commentsFactory, $rootScope, $scope, $localStorage, $timeout, __env) {
            // Set this to self to avoid conflicts
            const self = this;

            // Set the user
            self.user = $localStorage.user;

            // Set the ethos colors
            self.ethosColors = ['pink', 'green', 'orange', 'blue'];

            // Set the state to track active menu items
            self.state = $state;

            // Set the comment buttons here
            self.sendNewCommentButton = 'Send';
            self.sendNewReplyButton = 'Send';
            self.editCommentButton = 'Save';
            self.commentSortOptions = [{
                value: false,
                text: 'Oldest First'
            }, {
                value: true,
                text: 'Newest First'
            }];

            // Run when component is ready
            self.$onInit = function () {
                self.export = __env.apiUrl + 'table/view/export';
                // Check if we searching a brand
                if($stateParams.brand) {
                    $timeout(function () {
                        $rootScope.$broadcast('search-brand', {
                            brand: $stateParams.brand
                        });
                    });
                }

                // Set the ment.io people eobject
                self.people = self.users.map(function (obj) {
                    return {
                        label: obj.name + ' ' + obj.surname,
                        id: obj.id
                    }
                });
            };

            // Handle edit pyramid
            self.editPyramid = function (pyramid) {
                $state.go('app.build-pyramid', {
                    pyramid: pyramid
                });
            }

            // Show the pyramid
            self.showPyramid = function (e, pyramid) {
                const el = $(e.currentTarget);
                // Toggle the selected pyramid row
                el.children('.pyramid-info').toggle();
                // Toggle arrow state
                el.find('.pyramid-actions > button.arrow > .fa').toggle();

                /**
                 * GOOGLE DRIVE FUNCTIONS
                 */

                // Set the initial folder_id & file_id array with
                pyramid.google = {};
                pyramid.google.folder_id = [];
                pyramid.google.folder_name = []; // Didnt want to modify the main folder_id array
                pyramid.google.folders = [];
                pyramid.google.file_id = [];
                pyramid.google.files = [];

                // The Browser API key obtained from the Google API Console.
                //const developerKey = 'AIzaSyDvmE3ZZbsbUbEOXgnwr5UZ5L5zZ2hqP2E';
                const developerKey = 'AIzaSyA84wbERGRYr1nJHDckeKGOMzfvPryei0o'; // lliam


                // The Client ID obtained from the Google API Console. Replace with your own Client ID.
                //const clientId = "1018936847217-4eble25sq5bfpqjqhtf8ilrr6e2fo1au.apps.googleusercontent.com"
                const clientId = "744741621323-57is6rqjsfn967v03khs6cif578e6meu.apps.googleusercontent.com"; //liam

                // Scope to use to access user's photos.
                const scope = ['https://www.googleapis.com/auth/drive'];

                gapi.auth.authorize({
                    'client_id': clientId,
                    'scope': scope,
                    'immediate': true
                },
                    handleAuthResult);

                function handleAuthResult(authResult) {
                    if(authResult && !authResult.error) {
                        gapi.client.load('drive', 'v2', buildFilesFolders);
                    } else {
                        gapi.auth.authorize({
                            'client_id': clientId,
                            'scope': scope,
                            'immediate': false
                        },
                            handleAuthResult);
                    }
                }

                function buildFilesFolders() {
                    // List all files in folder
                    if(pyramid.folder_id) {
                        var folderString = pyramid.folder_id + '';
                        pyramid.google.folder_id = folderString.split(';'); // Create array of folder id's

                        self.buildFolderObject(pyramid); // Build folder object
                    }

                    // List all files
                    if(pyramid.file_id) {
                        var fileString = pyramid.file_id + '';
                        pyramid.google.file_id = fileString.split(';'); // Create array of file id's
                        self.buildFileObject(pyramid); // Build file object
                    }
                }
            };

            self.deletePyramid = function (id) {
                // Show prompt on delete
                swal({
                    title: 'Are you sure?',
                    text: 'You will not be able to recover this pyramid!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, keep it'
                }).then(function (result) {
                    if(result.value) {
                        pyramidsFactory.manage('delete', id)
                            .then(function (response) {
                                swal('Pyramid deleted', response.data.message, 'success')
                                    .then(function () {
                                        // Update the pyramids model
                                        $timeout(function () {
                                            self.pyramids = self.pyramids.filter(function (obj) {
                                                return obj.id !== id;
                                            });
                                        });
                                        // Update the unapproved pyramid count
                                        $rootScope.$broadcast('update-unapproved-count', {
                                            count: self.pyramids.length
                                        });
                                    });
                            }, function (error) {
                                console.log(error);
                            });
                    }
                });
            }

            self.managePyramid = function (action, id, message) {
                pyramidsFactory.manage(action, id)
                    .then(function (response) {
                        swal(message, response.data.message, 'success')
                            .then(function () {
                                // Fetch unapproved pyramids
                                pyramidsFactory.fetchUnapproved()
                                    .then(function (response) {
                                        // Update the unapproved count
                                        $rootScope.$broadcast('update-unapproved-count', {
                                            count: response.data.length
                                        });
                                    }, function (error) {
                                        console.log(error);
                                    });
                            });
                    }, function (error) {
                        console.log(error);
                    });
            }

            // Show additional pyramid info
            self.showPyramidInfo = function (e) {
                const el = $(e.currentTarget);
                // Change button text
                (e.currentTarget.textContent === 'Show Additional Info') ? e.currentTarget.textContent = 'Hide Additional Info' : e.currentTarget.textContent = 'Show Additional Info';
                // Scale and translate pyramid
                el.parents('.pyramid-section').find('.pyramids-container').toggleClass('scale');
                // Toggle pyramid details view
                el.parents('.pyramid-section').find('.pyramid-details').toggle();
            };

            // Show the pyramid comments
            self.showComments = function (e) {
                const el = $(e.currentTarget);
                // Change button text
                (e.currentTarget.textContent === 'View Comments') ? e.currentTarget.textContent = 'Hide Comments' : e.currentTarget.textContent = 'View Comments';
                // Toggle pyramid comments
                el.parents('.pyramid-section').find('.comments').toggle();
                // Toggle comment sort filter
                el.parents('.pyramid-section').find('.comment-sort').toggle();
            };

            // Define the mentions
            self.mentions = [];

            self.mentioSelect = function (obj) {
                self.mentions.push(obj.id);
                return '@' + obj.label;
            };

            // Add new comment
            self.addComment = function (id, comment) {
                self.sendNewCommentButton = 'Sending..';

                const input = {
                    message: comment,
                    pyramid_id: id,
                    mentions: self.mentions
                }

                commentsFactory.add(input)
                    .then(function (response) {
                        // Get the pyramid we are working with
                        angular.forEach(self.pyramids, function (value, key) {
                            if(self.pyramids[key].id === id) {
                                // Add the comment
                                self.pyramids[key].comment = response.data.comment;
                                // Clear comment text box
                                self.pyramids[key].newComment = '';
                            }
                        });
                        // Show the success message
                        swal({
                            title: 'Comment sent!',
                            text: response.message,
                            type: 'success'
                        }).then(function () {
                            $timeout(function () {
                                self.sendNewCommentButton = 'Send'; // Change button text back
                                // Reset mentions
                                self.mentions = [];
                            });
                        });
                    }, function (error) {
                        console.log(error);
                    });
            };

            // Delete comment
            self.deleteComment = function (id, commentId) {
                swal({
                    title: 'Are you sure?',
                    text: 'You will not be able to recover this comment!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, keep it'
                }).then(function (response) {
                    if(response.value) {
                        commentsFactory.delete(commentId)
                            .then(function (response) {
                                // Get the pyramid we are working with
                                angular.forEach(self.pyramids, function (value, key) {
                                    if(self.pyramids[key].id == id) {
                                        // Update the comments
                                        self.pyramids[key].comment = response.data.comment;
                                    }
                                });
                                // Send success message
                                swal({
                                    title: 'Comment Deleted!',
                                    text: response.message,
                                    type: 'success'
                                }).then(function () {
                                    $timeout(function () {
                                        self.sendNewCommentButton = 'Send'; // Change button text back
                                    });
                                });
                            }, function (error) {
                                console.log(error);
                            });
                    } else {
                        // Dismass the modal
                    }
                });
            };

            // Toggle comment reply based in pyramid id
            self.toggleReply = function (i) {
                $('.comment-reply#reply-' + i).toggle();
            };

            // Add Reply
            self.addReply = function (id, commentId, reply) {
                self.sendNewReplyButton = 'Sending..';

                console.log(self.mentions);

                const input = {
                    message: reply,
                    pyramid_id: id,
                    reply_id: commentId,
                    mentions: self.mentions
                }

                commentsFactory.reply(input)
                    .then(function (response) {
                        // Get the pyramid we are working with
                        angular.forEach(self.pyramids, function (value, key) {
                            if(self.pyramids[key].id === id) {
                                // Add the comment
                                self.pyramids[key].comment = response.data.comment;
                                // Clear reply text box
                                self.pyramids[key].newReply = '';
                            }
                        });

                        // Show the success message
                        swal({
                            title: 'Comment sent!',
                            text: response.message,
                            type: 'success'
                        }).then(function () {
                            $timeout(function () {
                                self.sendNewReplyButton = 'Send'; // Change button text back
                                // Reset mentions
                                self.mentions = [];
                            });
                        });
                    }, function (error) {
                        console.log(error);
                    });
            };

            // Toggle comment edit based on pyramid id
            self.toggleEdit = function (i) {
                $('.comment-message#edit-comment-' + i).toggle();
            };

            // Toggle comment reply edit based on pyramid id
            self.toggleEditReply = function (i) {
                $('.comment-message#edit-reply-' + i).toggle();
            };

            // Edit Comment
            self.editComment = function (id, commentId, comment) {
                self.editCommentButton = 'Saving..';

                console.log(self.mentions);

                const input = {
                    message: comment,
                    pyramid_id: id,
                    mentions: self.mentions
                }
                commentsFactory.edit(input, commentId)
                    .then(function (response) {
                        // Get the pyramid we are working with
                        angular.forEach(self.pyramids, function (value, key) {
                            if(self.pyramids[key].id === id) {
                                // Add the edited comment
                                self.pyramids[key].comment = response.data.comment;
                            }
                        });

                        // Show the success message
                        swal({
                            title: 'Comment edited',
                            text: response.message,
                            type: 'success'
                        }).then(function () {
                            $timeout(function () {
                                self.editCommentButton = 'Save'; // Change button text back
                                // Reset mentions
                                self.mentions = [];
                            });
                        });
                    }, function (error) {
                        console.log(error);
                    });
            }

            /**
             * GOOGLE DRIVE FUNCTIONS
             */
            var backOff = 1200; // Exponential backOff
            var count = 1; // Track file count as Google only allows 10 API requests per 1 second

            // Build the folder object function
            self.buildFolderObject = function (pyramid) {
                // Build the folders
                angular.forEach(pyramid.google.folder_id, function (value, key) {
                    var request = gapi.client.drive.files.get({
                        'fileId': value
                    });
                    request.execute(function (response) {
                        var folderName = response.title
                        // Set the folder object
                        var folder = {
                            folder_id: value,
                            folder_name: folderName,
                            files: []
                        }
                        // Build folder object
                        retrieveAllFilesInFolder(value, function (result) {
                            angular.forEach(result, function (v, k) {
                                getFileProps(v.id, function (result) {
                                    // console.log(result);
                                    if(result.code === 403) { // Re-run if we get an error
                                        if(backOff > 17000) { // Track exponential backOff to retry
                                            backOff = 1200;
                                        } else {
                                            backOff = backOff * 2;
                                        }

                                        getFileProps(v.id, function (result) {
                                            // Set the file object
                                            var file = {
                                                file_id: result.id,
                                                thumbnailLink: result.thumbnailLink,
                                                title: result.title,
                                                embedLink: result.embedLink
                                            }
                                            // Check if folder has sub folders
                                            if(result.thumbnailLink !== undefined) {
                                                // Push file to files array
                                                folder.files.push(file);
                                            }
                                        });
                                    } else {
                                        // Set the file object
                                        var file = {
                                            file_id: result.id,
                                            thumbnailLink: result.thumbnailLink,
                                            title: result.title,
                                            embedLink: result.embedLink
                                        }
                                        // Check if folder has sub folders
                                        if(result.thumbnailLink !== undefined) {
                                            // Push file to files array
                                            folder.files.push(file);
                                        }
                                    }
                                });
                            });
                            // Check if folder ha been duplicated
                            var duplicate = pyramid.google.folders.some(function (el) {
                                return el.folder_id === value;
                            });
                            // Only push to folder array if not duplicate
                            $timeout(function () {
                                (!duplicate) ? pyramid.google.folders.push(folder) : console.log('Folder duplicate: folder not added');
                            });

                            $timeout(function () {
                                console.log(pyramid.google.folders);
                            }, 20000);
                        });
                    });
                });
            }


            // Build the file object function
            self.buildFileObject = function (pyramid) {
                // Build the files object
                angular.forEach(pyramid.google.file_id, function (value, key) {
                    getFileProps(value, function (result) {
                        // Set the file object
                        var file = {
                            file_id: result.id,
                            thumbnailLink: result.thumbnailLink,
                            title: result.title,
                            embedLink: result.embedLink
                        }
                        // Check if file has been duplciated
                        var duplicate = pyramid.google.files.some(function (el) {
                            return el.file_id === value;
                        });
                        // Only push to file array if not duplicate
                        $timeout(function () {
                            (!duplicate) ? pyramid.google.files.push(file) : console.log('File duplicate: file not added');
                        })
                    });
                });

                $timeout(function () {
                    console.log(pyramid.google.files);
                }, 5000);
            }

            // Retrieve list of files in folder
            function retrieveAllFilesInFolder(folderId, callback) {
                var retrievePageOfChildren = function (request, result) {
                    request.execute(function (resp) {
                        result = result.concat(resp.items);
                        var nextPageToken = resp.nextPageToken;
                        if(nextPageToken) {
                            request = gapi.client.drive.children.list({
                                'folderId': folderId,
                                'pageToken': nextPageToken
                            });
                            retrievePageOfChildren(request, result);
                        } else {
                            callback(result);
                        }
                    });
                }

                var initialRequest = gapi.client.drive.children.list({
                    'folderId': folderId
                });
                retrievePageOfChildren(initialRequest, []);
            }

            function getFileProps(fileId, callback) {
                $timeout(function () {
                    self.sharingGoogle = true;
                }); // Show the loader

                const request = gapi.client.drive.files.get({
                    'fileId': fileId
                });
                count++
                if(count >= 10) {
                    $timeout(function () {
                        self.sharingGoogle = false;
                        request.execute(function (resp) {
                            callback(resp);
                        });
                    }, backOff);
                } else {
                    $timeout(function () {
                        self.sharingGoogle = false;
                        request.execute(function (resp) {
                            callback(resp);
                        });
                    });
                }
            }
        }
    });
