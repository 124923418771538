angular
    .module('app')
    .factory('userFactory', function (__env, $http, $localStorage) {
        return {
            fetch: function () {
                if (__env.enableDebug) {
                    console.log('userFactory.fetch ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'user',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            fetchAll: function () {
                if (__env.enableDebug) {
                    console.log('userFactory.fetchAll ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'users',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            create: function (input) {
                if (__env.enableDebug) {
                    console.log('userFactory.create ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'user/create',
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            update: function (input) {
                if (__env.enableDebug) {
                    console.log('userFactory.update ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'user/update/' + input.id,
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            delete: function (id) {
                if (__env.enableDebug) {
                    console.log('userFactory.delete ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'user/delete/' + id,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            toggleBrand: function (action, input) {
                if (__env.enableDebug) {
                    console.log('userFactory.toggleBrand ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + action,
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            }
        };
    });
