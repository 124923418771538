angular
    .module("app", [
		'ngRaven',
        "ui.router",
        "ngStorage",
        "angular-loading-bar",
        "19degrees.ngSweetAlert2",
        "ui.select",
        "ngSanitize",
		"mentio",
    ])
    .constant("__env", {
        //apiUrl: 'http://localhost:8000/', // Development
        // apiUrl: "api.believerspyramid.com/", // Staging
        apiUrl: "api.believerspyramid.com/", // Production
        apiID: "1",
        apiSecret: "8h8j9589h",
        enableDebug: true
    })
    .run(function ($rootScope, $state, $transitions, $localStorage, $window) {
        // On successful state load - Object takes 'from' an 'to' states (optional)
        $transitions.onSuccess({}, function (ev, to, toParams, from, fromParams) {
            // Check if current state is restricted or not (defined in routes.js)
            const restricted = $state.current.restricted;

            $rootScope.state = window.location.pathname;
            // Go to login page if user object is not present (user has not been authenticated)
            if (restricted && !$localStorage.accessToken) {
                $state.go("login");
            }
            // Load all pyramdids as default view
            if (restricted && $state.current.autoActivateChild) {
                $state.go($state.current.autoActivateChild);
            }
            // Scrollt to top of window on state change
            $window.scrollTo(0, 0);
        });
    });