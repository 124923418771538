angular
    .module('app')
    .component('reset', {
        templateUrl: 'app/auth/reset.html',
        controllerAs: 'vm',

        controller: function ($timeout, authFactory, sweetAlert) {
            // Set this to self to avoid conflicts
            const self = this;
            self.$onInit = function () {
                // Set the initial reset button
                self.button = 'Reset Password'
            };

            // Handle password reset
            self.resetPasswordHandler = function () {
                // Modify button text
                self.button = 'Resetting password...';
                // Set the input object
                const input = {
                    email: self.email
                };

                // Request reset password token 
                authFactory.reset(input).then(function (result) {
                    // Show sweet alert with callback
                    sweetAlert.swal({
                        title: 'Great!',
                        text: 'Please check your email for details',
                        type: 'success'
                    }).then(function () {
                        $timeout(function () {
                            // Change rest button text back
                            self.button = 'Reset Password';
                            // Reset input fields
                            self.email = null;
                        });
                    });
                }, function (error) {
                    // Show sweet alert with callback
                    sweetAlert.swal({
                        title: 'Oops!',
                        text: error.data.message,
                        type: 'error'
                    }).then(function () {
                        $timeout(function () {
                            // Change rest button text back
                            self.button = 'Reset';
                        });
                    });
                });
            };
        }
    });
