angular
    .module('app')
    .factory('pyramidsFactory', function (__env, $http, $localStorage) {
        return {
            create: function (input) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.create ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'pyramid/create',
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            update: function (input) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.create ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'pyramid/update/' + input.id,
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            search: function (filter) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.search ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'pyramids/search',
                        data: filter,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            manage: function (action, id) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.search ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'pyramid/' + action + '/' + id,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            fetchAll: function () {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.fetchAll ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'pyramids',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            fetchUnapproved: function () {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.fetchUnapproved ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'pyramids/unapproved',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },


            fetchCategories: function () {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.fetchCategories ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'pyramids/categories',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            createCategory: function (input) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.createCategory ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'pyramids/categories/create',
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            deleteCategory: function (input) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.deleteCategory ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'pyramids/categories/delete/' + input.id,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            fetchEthos: function () {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.fetchEthos ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'ethos',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            saveEthos: function (input) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.saveEthos ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'ethos/update/' + input.id,
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            fetchAuthors: function () {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.fetchAthors ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'authors',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            fetchThemes: function () {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.fetchThemes ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'theme',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            createTheme: function (input) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.createTheme ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'theme/create',
                        data: input,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            deleteTheme: function (input) {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.deleteTheme ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'theme/delete/' + input.id,
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            },
            export: function () {
                if (__env.enableDebug) {
                    console.log('pyramidsFactory.export ran');
                }
                return $http({
                        method: 'GET',
                        url: __env.apiUrl + 'table/view/export',
                        headers: {
                            Authorization: 'Bearer ' + $localStorage.accessToken
                        }
                    })
                    .then(function (response) {
                        return response;
                    });
            }
        };
    });
