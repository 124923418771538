angular
    .module('app')
    .factory('authFactory', function ($state, $http, $localStorage, __env) {
        return {
            login: function (input) {
                if (__env.enableDebug) {
                    console.log('authFactory.login ran');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'oauth/token',
                        data: input
                    })
                    .then(function (response) {
                        return response;
                    });
            },

            /**
             * Logout the user by resetting the users local storage variables
             */
            logout: function () {
                if (__env.enableDebug) {
                    console.log('authFactory.logout ran');
                }
                // Reset the user local storage variables
                $localStorage.$reset();

                // Go to login state
                $state.go('login');
            },

            /**
             * Request reset password token
             * @param  {object} $input Users email address
             * @return {json} Returns a success or error message
             */
            reset: function (input) {
                if (__env.enableDebug) {
                    console.log('loginService.reset ran **********');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'password/email',
                        data: input
                    })
                    .then(function (response) {
                        return response;
                    })
            },

            /**
             * ResetPassword adds the user new pass
             */
            resetPassword: function (input) {
                if (__env.enableDebug) {
                    console.log('loginService.resetPassword ran **********');
                }
                return $http({
                        method: 'POST',
                        url: __env.apiUrl + 'password/reset',
                        data: input
                    })
                    .then(function (response) {
                        return response;
                    });
            }
            /**
             * End Reset Password
             */
        };
    });
