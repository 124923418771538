angular
    .module('app')
    .component('superAdmin', {
        templateUrl: 'app/super-admin.html',
        controllerAs: 'vm',
        bindings: {
            categories: '<',
            users: '<',
            themes: '<'
        },

        controller: function (pyramidsFactory, userFactory, $timeout) {
            // Set this to self to avoid conflicts
            const self = this;

            // Run when component is ready
            self.$onInit = function () {
                // Set the role object
                self.roles = [{
                    id: 1,
                    name: 'Super Admin'
                }, {
                    id: 2,
                    name: 'Admin'
                }, {
                    id: 3,
                    name: 'User'
                }];
            }

            // Add new category
            self.handleCategoryCreate = function () {
                const input = {
                    category: self.createCategory
                }

                // Check if exisiting category
                var category = self.categories.find(function (obj) {
                    return obj.category.toLowerCase() === self.createCategory.toLowerCase();
                });

                if (category === undefined) {
                    pyramidsFactory.createCategory(input)
                        .then(function (response) {
                            // Add the newly created category to our categories model
                            self.categories.push(response.data);

                            swal('Great!', 'The new category has been successfully added!', 'success');

                        }, function (error) {
                            console.log(error);
                        });
                } else {
                    swal('Oops!', 'This category already exists', 'error');
                }
            }

            // Delete a category
            self.handleCategoryDelete = function () {
                swal({
                    title: 'Are you sure?',
                    text: 'You will not be able to recover this category!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, keep it'
                }).then(function (result) {
                    if (result.value) {
                        const input = {
                            id: self.deleteCategory.id
                        }
                        pyramidsFactory.deleteCategory(input)
                            .then(function (response) {
                                // Update the themes model
                                self.categories = self.categories.filter(function (obj) {
                                    return obj.id !== self.deleteCategory.id;
                                });
                                swal('Category Deleted', response.message, 'success');
                            });
                    } else {
                        console.log(result + 'nothing delete');
                    }
                });
            }

            // Toggle user brands section visibility
            self.toggleUserBrands = function (i) {
                $('#user-brands-' + i).toggle();
            }

            // Update the user
            self.updateUser = function (input) {
                userFactory.update(input)
                    .then(function (response) {
                        console.log(response);
                    }, function (error) {
                        console.log(error);
                    });
            }


            // Delete the user
            self.deleteUser = function (id) {
                swal({
                    title: 'Are you sure?',
                    text: 'You will not be able to recover this user!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, keep it'
                }).then(function (result) {
                    if (result.value) {
                        userFactory.delete(id)
                            .then(function (response) {
                                swal('User Deleted', response.message, 'success');
                                // Update the user model
                                $timeout(function () {
                                    self.users = self.users.filter(function (obj) {
                                        return obj.id !== id;
                                    });
                                });
                            }, function (error) {
                                console.log(error);
                            });
                    }
                });
            }

            // Toggle the users individual brand
            self.toggleUserBrand = function (action, brand, user) {
                const input = {
                    pyramid_brand: brand,
                    user_id: user
                }

                userFactory.toggleBrand(action, input)
                    .then(function (response) {
                        console.log(response);
                    }, function (error) {
                        console.log(error);
                    });
            }

            // Add new user
            self.handleUserCreate = function () {
                const input = self.createUser;
                userFactory.create(input)
                    .then(function (response) {
                        // Fetch the brands to add to user model
                        userFactory.fetchAll()
                            .then(function (response) {
                                // Update the user model
                                $timeout(function () {
                                    self.users = response.data;
                                });
                            });

                        swal('Great!', 'The new user has been successfully created!', 'success');

                        // Clear the inputs
                        self.createUser = null;

                    }, function (error) {
                        swal('Oops!', error.data.message, 'error');
                    });
            }

            // Add new theme
            self.handleThemeCreate = function () {
                const input = {
                    name: self.createTheme
                }

                // Check if exisiting theme
                var theme = self.themes.find(function (obj) {
                    return obj.name.toLowerCase() === self.createTheme.toLowerCase();
                });

                if (theme === undefined) {
                    pyramidsFactory.createTheme(input)
                        .then(function (response) {
                            // Add the newly created theme to our themes model
                            self.themes.push(response.data);

                            swal('Great!', 'The theme was successfully added', 'success');

                        }, function (error) {
                            console.log(error);
                        });
                } else {
                    swal('Oops!', 'This theme already exists', 'error');
                }
            }

            // Delete a category
            self.handleThemeDelete = function () {
                swal({
                    title: 'Are you sure?',
                    text: 'You will not be able to recover this theme!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    cancelButtonText: 'No, keep it'
                }).then(function (result) {
                    if (result.value) {
                        const input = {
                            id: self.deleteTheme.id
                        }
                        pyramidsFactory.deleteTheme(input)
                            .then(function (response) {
                                // Update the themes model
                                self.themes = self.themes.filter(function (obj) {
                                    return obj.id !== self.deleteTheme.id;
                                });
                                swal('Theme Deleted', response.data.message, 'success');
                            });
                    } else {
                        console.log(result + 'nothing delete');
                    }
                });
            }
        }
    });
