angular
    .module('app')
    .component('app', {
        templateUrl: 'app/main.html',
        controllerAs: 'vm',

        controller: function () {

        }
    });
